import React from "react"
import { graphql, Link } from "gatsby"
import VideoListExternal from "../../components/VideoListExternal"

const FilmsGratuits = props => {
    const url = "https://cinetimes.org/collections/films-gratuits-youtube/"
    const edges = props.data.allVideo.edges
    const title = `${edges.length} Films gratuits à voir sur YouTube` 
    const description = `Sélection de ${edges.length} films, à voir gratuitement sur YouTube, DailyMotion et Archive.org`
    return (
        <VideoListExternal url={url} title={title} description={description} edges={edges} similar={
            <>
                <Link to="/blog/films-gratuits/">Films gratuits</Link>, <Link to="/collections/dessins-animes-gratuits/">Dessins animés gratuits</Link>, <Link to="/collections/documentaires-gratuits/">Documentaires gratuits</Link>
            </>
        }/>
    )
}

export default FilmsGratuits


  
export const query = graphql`
  query {
    allVideo(filter: {video_taken_down: {eq: false}, movie_genres: {nin: ["Animation"]}}, sort: {fields: video_id, order: DESC} ) {
        edges {
          node {
            video_id
            video_subtitles
            video_audio
            video_title
            translation_synopsis
            video_taken_down
            video_source
            video_url
            translation_title
            translation_slug
            translation_poster {
                url
            }
            movie_genres
          }
        }
    }
  }
`